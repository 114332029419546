import React from "react";
import { useParams } from "react-router-dom";
import { allCategories } from "../../data/data";
import { YoutubeEmbed } from "./YoutubeEmbedded";
import { Animation } from "../../components/Animation";
import songOuch1 from "../../data/images/songOuchOuch1.png"
import songOuch2 from "../../data/images/songOuchOuch2.png"
import songChou1 from "../../data/images/songChouChou1.png"
import songChou2 from "../../data/images/songChouChou2.png"

const layout: React.CSSProperties = {
  display: "flex",
  margin: "40px 40px 0px 40px",
  justifyContent: "center",
};

const animationContainerStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
}
export const SongPage = () => {
  const { catValue, songId } = useParams();
  const category = allCategories.find((cat) => cat.value === catValue);
  const song = category?.songs.find((currentSong) => currentSong.id === songId);

  if(!category || !song){
    console.error("This category doesn't exist")
    return null
  }
  return (
    <>
    <div style={animationContainerStyle}>
      <Animation images={[songOuch1,songOuch2]} interval={500}/>
      <h1 style={{ ...layout, textAlign: "center" }}>
        {`${song.title} - ${song.author}`}
      </h1>
      <Animation images={[songChou2,songChou1]} interval={500}/>
    </div>
      <h4 style={{ ...layout, textAlign: "center" }}>
        Si la vidéo youtube est indisponible, clique&nbsp;<a href={`https://www.youtube.com/watch?v=${song.url}`}>ici</a>
      </h4>
      <div style={layout}>
        <YoutubeEmbed url={song.url} />
      </div>
    </>
  );
  
};