import { allCategories } from "../../data/data";
import { CategoryButton } from "./CategoryButton";
const listStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "15px",
  alignItems: "flex-end",
};
export const CategoryList = () => {
  return (
    <div style={listStyle}>
      {allCategories.map((category) => {
        return <CategoryButton category={category} />;
      })}
    </div>
  );
};
