import { useEffect, useState } from "react";

export const Animation = ({ images, interval }: { images: string[], interval?: number }) => {
  const [displayedImageIndex, setDisplayedImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDisplayedImageIndex(
        (currentImageIndex) => (currentImageIndex + 1) % images.length
      );
    }, interval ?? 200);

    return () => clearInterval(intervalId);
  });
  
  if(images.length === 0){
    return null;
  }

  return (
    <>
      <img src={images[displayedImageIndex]} alt="anim"></img>
    </>
  );
};
