import { Song } from "../../data/data";
import { Animation } from "../../components/Animation";
import "./SongButton.css";
import React from "react";
import cute2 from "../../data/images/cute2.png";
import cute1 from "../../data/images/cute1.png";

export const SongButton = ({ song, handleNavigation }: { song: Song, handleNavigation: (id: string) => void }) => {
  return (
    <div className="song-container">
      <div className="hidden-animation">
        <Animation images={[cute1, cute2]} />
      </div>
      <div className="song-card" onClick={() => handleNavigation(song.id)}>
        <h2 className="title-song">{`${song.title} - ${song.author}`}</h2>
      </div>
    </div>
  );
};
