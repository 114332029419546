import React from "react";
import { useParams } from "react-router-dom";
import { allCategories } from "../../data/data";
import { SongsList } from "./SongsList";

const layout: React.CSSProperties = {
  width: "60%",
  display: "flex",
  margin: "auto",
  justifyContent: "center",
  marginTop: "40px",
};
export const SelectSong = () => {
  const { catValue } = useParams();
  const category = allCategories.find((cat) => cat.value === catValue);

  if(!category){
    console.error("This category doesn't exist")
    return null
  }
  return (
    <>
      <h1 style={{ ...layout, textAlign: "center" }}>
        {`Voici la listes des chousons que je te propose dans la catégorie ${category.name}!`}
      </h1>
      <div style={layout}>
        <SongsList category={category} />
      </div>
    </>
  );
};