import { useNavigate } from "react-router-dom";
import { Category } from "../../data/data";
import { Animation } from "../../components/Animation";
import "./CategoryButton.css";

export const CategoryButton = ({ category }: { category: Category }) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(`category/${category.value}`)
  }
  return (
    <div className="category-container">
      <div className="hidden-animation">
        <Animation images={category.images} />
      </div>
      <div className="category-card" onClick={() => handleNavigation()}>
        <h2 className="title-category">{category.name}</h2>
      </div>
    </div>
  );
};
