import sad1 from "./images/OuchOuch1.png";
import sad2 from "./images/OuchOuch2.png";
import motiv1 from "./images/motivation1.png";
import motiv2 from "./images/motivation2.png";
import aventure1 from "./images/aventure1.png";
import aventure2 from "./images/aventure2.png";
import love1 from "./images/love1.png";
import love2 from "./images/love2.png";
import classic1 from "./images/classic1.png";
import classic2 from "./images/classic2.png";

export type Song = {
  id: string;
  author: string;
  title: string;
  url: string;
};

export type Category = {
  images: string[];
  name: string;
  value: string;
  songs: Song[];
};

export const allCategories: Category[] = [
  {
    images: [sad1, sad2],
    name: "OuchOuch songs",
    value: "sad",
    songs: [
      {
        id: "1",
        author: "Alec Benjamin",
        title: "Devil doesn't bargain",
        url: "6zNHZkT3DXk",
      },
      {
        id: "2",
        author: "Alec Benjamin",
        title: "Water fountain",
        url: "5ado75KpV9w",
      },
      {
        id: "3",
        author: "Maneskin",
        title: "La parole lontane",
        url: "R_EiKMW5ebU",
      },
      {
        id: "4",
        author: "Lonepsi",
        title: "Comme deux étoiles",
        url: "Ztbd8jOX3Qg",
      },
      {
        id: "5",
        author: "Skylar Grey",
        title: "Coming Home",
        url: "k84QxVJd0tI",
      },
    ],
  },
  {
    images: [motiv1,motiv2],
    name: "Motivation songs",
    value: "motivation",
    songs: [
      {
        id: "6",
        author: "Scylla",
        title: "Charbon",
        url: "7Mgu63-VVyY",
      },
      {
        id: "7",
        author: "Bigflo & Oli",
        title: "Je suis",
        url: "8EyoKfM2Bv4",
      },
      {
        id: "8",
        author: "Scarlet Pleasure",
        title: "What a life",
        url: "FjVVqpZw-wg",
      },
      {
        id: "9",
        author: "Scylla",
        title: "Le fantôme sous les toits",
        url: "xWgHV4fd-Ts",
      },
      {
        id: "10",
        author: "B.o.b",
        title: "Airplane",
        url: "kn6-c223DUU",
      },
    ],
  },
  {
    images: [aventure1, aventure2],
    name: "Partons à l'aventure !",
    value: "aventure",
    songs: [
      {
        id: "11",
        author: "Charlie Winston",
        title: "Like a Hobo",
        url: "9nOd5_Bdc8I",
      },
      {
        id: "12",
        author: "Aurora",
        title: "Heathens",
        url: "etIXDCSB_VU",
      },
      {
        id: "13",
        author: "Of Monsters and men",
        title: "Little Talks",
        url: "ghb6eDopW8I",
      },
      {
        id: "14",
        author: "Of Monsters And Men",
        title: "Dirty Paws",
        url: "mCHUw7ACS8o",
      },
      {
        id: "15",
        author: "Avicii",
        title: "The nights",
        url: "2S0QhGGO1gQ",
      },
    ],
  },
  {
    images: [love1, love2],
    name: "Love songs",
    value: "love",
    songs: [
      {
        id: "16",
        author: "Gnash",
        title: "That one song",
        url: "Ra4ks_lJGbk",
      },
      {
        id: "17",
        author: "Foster",
        title: "Fools",
        url: "wEINlbIcVS0",
      },
      {
        id: "18",
        author: "Greg Laswell",
        title: "Comes and goes",
        url: "pEFxfVyz4Uc",
      },
      {
        id: "19",
        author: "Calogero",
        title: "Avant toi",
        url: "JuEA4zPzpO8",
      },
      {
        id: "20",
        author: "Lilian renaud",
        title: "J’attends",
        url: "DBtEgf9c8tQ",
      },
    ],
  },
  {
    images: [classic1, classic2],
    name: "Musique Classique",
    value: "classic",
    songs: [
      {
        id: "21",
        author: "Ludovico Einaudi",
        title: "Experience",
        url: "_VONMkKkdf4",
      },
      {
        id: "22",
        author: "Ludovico Einaudi",
        title: "Una mattina",
        url: "j1Ck42-_btY",
      },
      {
        id: "23",
        author: "The Giver",
        title: "Rosemary theme",
        url: "u0tJJsILopU",
      },
      {
        id: "24",
        author: "Yann Tiersen",
        title: "Comptine d'un autre été",
        url: "znfYwABeSZ0",
      },
      {
        id: "25",
        author: "Sofiane Pamart",
        title: "Planet",
        url: "Ngy8JdAbwJ4",
      },
    ],
  },
];
