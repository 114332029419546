import { Category } from "../../data/data";
import React from "react";
import { SongButton } from "./SongButton";
import { useNavigate } from "react-router";

const listStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "15px",
  alignItems: "flex-end",
};
export const SongsList = ({category}:{category: Category}) => {
  const navigate = useNavigate()
  const handleNavigation = (songId: string) => {
    navigate(`/category/${category.value}/${songId}`)
  }
  return (
    <div style={listStyle}>
      {category.songs.map((song) => {
        return <SongButton song={song} handleNavigation={handleNavigation} />;
      })}
    </div>
  );
};
