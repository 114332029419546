import React from "react";
import "../../App.css";
import { CategoryList } from "./CategoryList";

const layout: React.CSSProperties = {
  width: "60%",
  display: "flex",
  margin: "auto",
  justifyContent: "center",
  marginTop: "40px",
};
export const SelectCategory = () => {
  return (
    <>
      <h1 style={{ ...layout, textAlign: "center" }}>
        Choisis une catégorie chou pour écouter une chouson qui colle à ton
        humeur !
      </h1>
      <div style={layout}>
        <CategoryList />
      </div>
    </>
  );
};